import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { BaseSpecialtyService } from '../services/base';
import { SpecialtyModel } from '../models';
import { CommonModule } from '@angular/common';
import { AmaModalComponent } from 'src/lib/components/modal/modal.component';
import { AmaCheckboxComponent } from 'src/lib/components/ama-checkbox/ama-checkbox.component';
import { AmaIconComponent } from 'src/lib/components/icon/icon.component';
import { Router } from '@angular/router';
class SpecialtyViewModel extends SpecialtyModel {
  public isChecked: boolean;
}

@Component({
  selector: 'app-browse-specialties',
  templateUrl: './browse-specialties.component.html',
  styleUrls: ['./browse-specialties.component.scss'],
  imports: [
    CommonModule,
    AmaModalComponent,
    AmaCheckboxComponent,
    AmaIconComponent
  ],
  standalone: true
})
export class BrowseSpecialtiesComponent implements OnChanges, OnDestroy {
  @Output() public searchClicked = new EventEmitter();
  @Output() public closeModal = new EventEmitter();
  @Output() public specialtiesChange = new EventEmitter();
  @Input() public isModalOpen = true;
  public subscription;

  public residencies: SpecialtyModel[] = [];
  public fellowships: SpecialtyModel[] = [];

  public selectedSpecialties: SpecialtyModel[] = [];
  public get currentlySelected(): number[] {
    return this.specialtyService.getSelectedArray();
  }

  public selectedTab = 'Residencies';
  public numSelected = 0;

  constructor(private specialtyService: BaseSpecialtyService, private router: Router) { }

  public ngOnChanges() {
    if (this.isModalOpen) { //execute once modal actually opens
      this.subscription = this.specialtyService.getAllSpecialties()
        .subscribe((specialties: SpecialtyViewModel[]) => {
          const selectedSpecialties = this.specialtyService.getSelectedSpecs();
          let convertedSelectedSpecialties = [];
          if (selectedSpecialties && selectedSpecialties.length > 0) {
            convertedSelectedSpecialties = this.selectedSpecialties = this.specialtyService.getSelectedSpecs().map((spec) => {
              return spec.nid;
            });
          }

          const joinedSpecs = convertedSelectedSpecialties.join(',');
          this.residencies = specialties.filter((spec: SpecialtyViewModel) => spec.type === 'Residency')
            .map(spec => {
              if (joinedSpecs.includes(spec.nid.toString()))
                spec.isChecked = true;
              else {
                spec.isChecked = false;
              }
              return spec;
            });
          this.fellowships = specialties.filter((spec: SpecialtyViewModel) => spec.type === 'Fellowship')
            .map(spec => {
              if (joinedSpecs.includes(spec.nid.toString()))
                spec.isChecked = true;
              else {
                spec.isChecked = false;
              }
              return spec;
            });
        });
    }
  }


  public onSpecialtySelected(isChecked: boolean, specialty: SpecialtyModel) {
    const sNid = specialty.nid;
    isChecked ? this.specialtyService.setSelected(specialty) : this.specialtyService.removeSelected(specialty.nid);
    this.selectedSpecialties = this.specialtyService.getSelectedSpecs();
    const spec = this.residencies.concat(this.fellowships).find(sp => sp.nid === specialty.nid);
    if (spec && spec.isChecked !== isChecked) {
      spec.isChecked = isChecked;
    }
    this.specialtiesChange.emit({ isChecked, sNid });
  }

  public onSearchClicked() {
    const selectedSpecs = (this.residencies.concat(this.fellowships)).filter((spec) => {
      if (spec.isChecked) {
        return spec;
      }
    });
    this.specialtyService.setSelectedSpecs(selectedSpecs);
    this.searchClicked.emit({ term: '' });
    this.isModalOpen = false;

    if (this.router.url.includes('/map')) {
      this.router.navigate([], {
        queryParams: {
          markersLoaded: false, // this toggles false on filter change in filter.comnponent.ts
        },
        queryParamsHandling: 'merge' // Preserve existing query parameters
      });
    }
  }

  public closeSpecialtiesModal() {
    this.isModalOpen = false;
    this.closeModal.emit({ modal: this.isModalOpen });
  }

  public changeTab(text) {
    this.selectedTab = text;
  }

  public closeModel() {
    this.isModalOpen = false;
    this.closeModal.emit();
  }

  public get canSearch(): boolean {
    return this.currentlySelected && this.currentlySelected.length > 0;
  }

  public get canSelect(): boolean {
    this.selectedSpecialties = this.specialtyService.getSelectedSpecs();
    return this.selectedSpecialties.length > 4 || false;
  }

  public getSelected() {
    this.selectedSpecialties = this.specialtyService.getSelectedSpecs();
  }

  public clearSelected() {
    this.specialtyService.clearSelected();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
